import { useState, useEffect, useRef, useCallback } from "react";
import "./App.css";
import logo from "./logo.jpeg"

function App() {
  const [message, setMessage] = useState("");
  const [messageOn, setMessageOn] = useState("ON");
  const [messageOff, setMessageOff] = useState("OFF");
  const [colorOn, setColorOn] = useState("#fff");
  const [colorOff, setColorOff] = useState("#fff");
  // const [delay, setDelay] = useState(2000);
  const [delay1, setDelay1] = useState(1000);
  const [delay2, setDelay2] = useState(3000);
  // const [time, setTime] = useState(0);
  const [start, setStart] = useState(false);

  const switchMessage = (cmessage, delay) => {
    setTimeout(() => {
      if (start)setMessage(cmessage);
    }, delay)
  
  }

  useEffect(() => {
    console.log({message, a: 'effect'})
    const currentMessage = message === messageOn ? messageOff : messageOn;
    const currentDelay = message === messageOn ? delay1 : delay2
    if (start) switchMessage(currentMessage, currentDelay)
  }, [message, start])
  
  return (
    <div className="App">
      <header className="App-header">
        <img id="logo" src={logo} alt="logo"/>
        <div>
          <input
            type="text"
            className="inputMessage"
            onChange={(e) => setMessageOn(e.target.value)}
            placeholder="MessageOn"
          disabled={start}
          />
        <input
          type="number"
            className="inputMessage"
          onChange={(e) => setDelay1(e.target.value)}
          placeholder="delay1"
          disabled={start}
        />
          <input
            type="color"
            className="inputColor"
            onChange={(e) => setColorOn(e.target.value)}
            placeholder="ColorOn"
          disabled={start}
          />
        </div>
        <div>
          <input
            type="text"
            className="inputMessage"
            onChange={(e) => setMessageOff(e.target.value)}
            placeholder="MessageOff"
          disabled={start}
          />
        <input
          type="number"
            className="inputMessage"
          onChange={(e) => setDelay2(e.target.value)}
          placeholder="delay2"
          disabled={start}
        />
          <input
            type="color"
            className="inputColor"
            onChange={(e) => setColorOff(e.target.value)}
            placeholder="ColorOff"
          disabled={start}
          />
        </div>
        <button
        className="buttonstart"
          disabled={start} onClick={() => setStart(true)}>{"START"}</button>
        <button
        className="buttonstop"
          disabled={!start} onClick={() => setStart(false)}>{"STOP"}</button>
      </header>
      <div
        className="App-body"
        style={{ backgroundColor: message === messageOn ? colorOn : colorOff }}
      >
        <p>{message}</p>
      </div>
      <div className="footer">Provided by SYNTRACT</div>
    </div>
    
  );
}

export default App;
